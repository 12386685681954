import React, { useContext } from 'react';

import SolanaWithWallet from './components/SolanaWithWallet';
import SolanaFixAddress from './components/SolanaFixAddress';
import './assets/css/App.css'
import RPCContext from './context/RPCContext';
import Modal from './components/Modal';

document.title = 'Solana Balance Tracker';
const App = () => {

  const { modalData, closeModal } = useContext(RPCContext)

  return (
    <div className='flex flex-col'>
      <SolanaFixAddress />
      <SolanaWithWallet />
      { modalData.show && <Modal modalData={modalData} closeModal={closeModal} /> }
    </div>
  );
};

export default App;
