import React, { useState, useMemo, useContext } from 'react';

import { WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { PhantomWalletAdapter, SolflareWalletAdapter, LedgerWalletAdapter, TorusWalletAdapter } from '@solana/wallet-adapter-wallets';

import '@solana/wallet-adapter-react-ui/styles.css'; 
import SendSol from './SendSol';
import RPCContext from '../context/RPCContext';
import WalletBalance from './WalletBalance';

const SolanaWithWallet = () => {
  
  const wallets = useMemo(() => [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    new LedgerWalletAdapter(),
    new TorusWalletAdapter(),
  ], []);

  const { connection, fetchBalance,  reloadSenderBalance, setReloadSenderBalance, openModal } = useContext(RPCContext)
  const [error, setError] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  return (
    <ConnectionProvider endpoint={connection.rpcEndpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <div className="flex justify-center items-start w-full px-7 pb-12">
            <div className="p-10 bg-gray-900 rounded-[10px] max-w-lg">
              <h2 className="text-[30px] text-center text-white font-bold">Send SOL</h2>
              <div className='mt-2 flex flex-col items-center gap-2'>
                <WalletBalance fetchBalance={fetchBalance} error={error} setError={setError} setIsConnected={setIsConnected} reloadSenderBalance={reloadSenderBalance} setReloadSenderBalance={setReloadSenderBalance} openModal={openModal} />
                <WalletMultiButton className='custom-wallet-button' />
                { isConnected && <SendSol /> }
              </div>
            </div>
          </div>
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default SolanaWithWallet;
