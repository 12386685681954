import React from 'react'

const Modal = ({ modalData, closeModal }) => {
    
  return (
    <div className='w-full h-dvh fixed top-0 left-0 right-0 z-10 bg-[#00000059] flex items-center justify-center'>
        <div className='bg-white max-w-lg w-full rounded-lg flex items-center justify-center flex-col p-11 relative'>
            <div className='mb-2'>
                {
                    modalData.displayasset === 'loading' ?
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> :
                    modalData.displayasset === 'success' ? 
                    <div className="success-checkmark">
                        <div className="check-icon">
                            <span className="icon-line line-tip"></span>
                            <span className="icon-line line-long"></span>
                            <div className="icon-circle"></div>
                            <div className="icon-fix"></div>
                        </div>
                    </div> :
                    modalData.displayasset === 'error' ? 
                    <div className="swal2-icon swal2-error swal2-animate-error-icon flex"><span className="swal2-x-mark"><span className="swal2-x-mark-line-left"></span><span className="swal2-x-mark-line-right"></span></span></div> : ''
                }
            </div>
            <h3 className='text-center text-2xl font-bold'>{modalData.title}</h3>
            <div className='absolute right-3 top-3 z-20 modalCloseBtn' onClick={closeModal}>
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <circle cx="12" cy="12" r="10" stroke="#1C274C" strokeWidth="1.5"></circle> <path d="M14.5 9.50002L9.5 14.5M9.49998 9.5L14.5 14.5" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round"></path> </g></svg>
            </div>
        </div>
    </div>
  )
}

export default Modal