import { useState, useEffect, createContext } from 'react'

import { Connection } from '@solana/web3.js';
const testRPC = 'https://devnet.helius-rpc.com/?api-key=50cbbd53-f439-43e1-ab67-1b81bd04df87'
const testRPCmain = 'https://mainnet.helius-rpc.com/?api-key=50cbbd53-f439-43e1-ab67-1b81bd04df87'
const connection = new Connection(testRPC);
// const connection = new Connection(process.env.REACT_APP_SOLANA_RPC_URL);

const RPCContext = createContext();

const RPCContextProvider = (props) => {


  const [reloadReceiverBalance, setReloadReceiverBalance] = useState(false)
  const [reloadSenderBalance, setReloadSenderBalance] = useState(false)

  const [modalData, setModalData] = useState({
    show: false,
    title: 'Fetching Balance',
    displayasset: 'loading'
  })

    const fetchBalance = async (publicKey) => {
        if (publicKey) {
          try {
            const balanceLamports = await connection.getBalance(publicKey);
            const balanceSol = balanceLamports / 1e9; 
            return { data: balanceSol, success: true}

          } catch (error) {
            console.error("Error fetching balance:", error);
            return { data: "Error fetching balance:", success: false}
          }
        }else{
            return { data: null, success: true}
        }
    };

    useEffect(() => {
        
    }, [])
    
    const closeModal = () => { 
      setModalData({
        show: false
      })
    }

    const openModal = (title, displayasset) => { 
      setModalData({
        show: true,
        title,
        displayasset
      })
    }

    return (
        <RPCContext.Provider value={{ connection, fetchBalance, reloadReceiverBalance, setReloadReceiverBalance, reloadSenderBalance, setReloadSenderBalance, modalData, setModalData, openModal, closeModal }}>
            {props.children}
        </RPCContext.Provider>
    )
}

export default RPCContext
export { RPCContextProvider }
