import React, { useEffect, useState } from 'react';

import { useWallet } from '@solana/wallet-adapter-react';

const WalletBalance = ({ fetchBalance, error, setError, setIsConnected, reloadSenderBalance, setReloadSenderBalance, openModal }) => {
  const { publicKey } = useWallet(); 
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);


  const getSolBalance = async () => {
    setLoading(true)
    
    const { data, success } = await fetchBalance(publicKey)

    if(reloadSenderBalance && success){
      
      setReloadSenderBalance(false)

      if(success) openModal('Sending SOL Success', 'success');
    }
    
    setLoading(false)

    if(!success){
      reloadSenderBalance && openModal(data, 'error');
      return setError(data);
    } 
    
    setBalance(data ? data.toFixed(4) : data)
    setError('')
  }
  
  useEffect(() => {
    getSolBalance()

    !publicKey ? setIsConnected(false) : setIsConnected(true)

  }, [publicKey]);

  useEffect(() => {
    if(reloadSenderBalance){
        setLoading(true)
        setTimeout(() => {
            getSolBalance();
        }, 15000);
    }
  }, [reloadSenderBalance]);

  if (!publicKey) {
    return <p className='text-base text-center text-white'>Please connect your wallet.</p>;
  }

  return (
      <div className='w-full'>
        <div>
            <p className="text-base text-center text-white">Your Wallet Address:</p>
            <h3 className="text-2xl text-[#16fb9a] break-all text-center">{publicKey.toString()}</h3>
        </div>
        <div className='mt-2'>
            <p className="text-base text-center text-white">Your Balance:</p>
            {
              error ? <p className="text-red-500 text-center text-base mt-3">{error}</p> :
              <h3 className="text-4xl text-[#9a44ff] text-center">{loading ? "Loading..." : `${balance} SOL`}</h3>
            }
        </div>
      </div>
  );
};

export default WalletBalance;
