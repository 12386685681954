import React, { useState, useContext } from 'react';
import { PublicKey, Transaction, SystemProgram } from '@solana/web3.js';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
import { useWallet } from '@solana/wallet-adapter-react';
import RPCContext from '../context/RPCContext';

const SendSol = () => {
    const { publicKey, signTransaction, sendTransaction } = useWallet();
    const [amount, setAmount] = useState('');
    const [sending, isSending] = useState(false);

    const { connection, setReloadReceiverBalance, setReloadSenderBalance, fetchBalance, openModal } = useContext(RPCContext)
    
 
    const handleSendSol = async () => {
        if (!publicKey) {
            openModal("Please connect your wallet first.", 'error');
            return;
        }

        isSending(true)
        try {
    
            if(!amount) return;

            const { data:balance, success } = await fetchBalance(publicKey)
            
            if(!success) return openModal("Error Fetching current balance", 'error');

            const lamports = parseFloat(amount) * 1e9; // Convert SOL to lamports

            if (balance < parseFloat(amount) + 0.0001) { // Adding a small buffer for network fees
                openModal("Insufficient funds to complete this transaction.", 'error');
                return;
            }

            const recipientPublicKey = new PublicKey('8YFmnGifjCaVvP4t2wBfmXfFSQmDpbavevSam3hPqgfX');

            let transaction = new Transaction().add(
                SystemProgram.transfer({
                    fromPubkey: publicKey,
                    toPubkey: recipientPublicKey,
                    lamports,
                })
            );

            transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;
            transaction.feePayer = publicKey;

            transaction = await signTransaction(transaction);
            const signature = await connection.sendRawTransaction(transaction.serialize());
            // const { signature } = await signAndSendTransaction(transaction);
            //const signature = await sendTransaction(transaction, connection);
            
            openModal(`Sending SOL`, 'loading');
            const confirmation = await connection.confirmTransaction(signature, 'confirmed');
        
            if (confirmation.value.err) {
                openModal("Transaction failed. Please try again.", 'error');
            } else {
                openModal(`Fetching new Balance`, 'loading');
                setReloadReceiverBalance(true)
                setReloadSenderBalance(true)
            }
            
            setAmount('');
        } catch (error) {
            console.error("Error sending SOL:", error);
            openModal(`Failed to send SOL. Please check the recipient address and amount.`, 'error');
        }finally{
            isSending(false)
        }
    };

    return (
        <div className='flex flex-col gap-4 mt-4 w-full'>
            <input
                type="number"
                placeholder="Amount in SOL"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                className='w-full px-4 py-3 text-xl text-white bg-gray-800 rounded-md text-center border border-solid border-slate-500'
            />
            <button onClick={(sending | (!amount | parseFloat(amount) <= 0) ) ? undefined :  handleSendSol} className={`w-full px-4 py-3 text-xl text-white bg-gradient-to-r from-[#16fb9a] to-[#9a44ff] rounded-md ${(sending | (!amount | parseFloat(amount) <= 0) ) ? "opacity-50" : ''}`} disabled={(sending | (!amount | parseFloat(amount) <= 0) ) ? true : false}>Send SOL</button>
        </div>
    );
};

export default SendSol;
