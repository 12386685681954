import React, { useContext, useEffect, useState } from "react";
import { PublicKey } from "@solana/web3.js";
import RPCContext from "../context/RPCContext";

const WALLET_ADDRESS = '8YFmnGifjCaVvP4t2wBfmXfFSQmDpbavevSam3hPqgfX'
// const WALLET_ADDRESS = process.env.REACT_APP_SOLANA_ADDRESS;
const POLL_INTERVAL = 30000; // Refresh every 30 seconds
const MAXSOL = 20000

var interval;

const SolanaFixAddress = () => {
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);
  const [barPercent, setBarPercent] = useState(0);
  const [loading, setLoading] = useState(true);
  const { fetchBalance, reloadReceiverBalance, setReloadReceiverBalance, } = useContext(RPCContext)

  const getSolBalance = async () => {
    setLoading(true)

    const publicKey = new PublicKey(WALLET_ADDRESS);

    const { data, success } = await fetchBalance(publicKey)

    setLoading(false)

    if(reloadReceiverBalance){
      setReloadReceiverBalance(false)
    }
    
    if(!success){
        setBarPercent(0)
        setError("Failed to fetch balance. Please check the address");
        return;
    }

    setError('')
    setBalance(data.toFixed(4));
      
    if(data > MAXSOL){
      setBarPercent(100)
    }else{
      var percent = (data/MAXSOL)*100
      setBarPercent(percent.toFixed(4))
    }
  }

  useEffect(() => {
    getSolBalance();
    interval = setInterval(getSolBalance, POLL_INTERVAL);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if(reloadReceiverBalance){
        setLoading(true)
        setTimeout(() => {
            clearInterval(interval);
            getSolBalance();
            interval = setInterval(getSolBalance, POLL_INTERVAL);
        }, 15000);
    }
  }, [reloadReceiverBalance]);

  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(WALLET_ADDRESS);
      setCopied(true)

      setTimeout(() => {
        setCopied(false)
      }, 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="flex justify-center items-center w-full px-7 h-dvh">
      <div className="p-10 bg-gray-900 rounded-[10px]">
        <h2 className="text-[30px] text-center text-white font-bold">Presale Wallet Balance (Solana)</h2>
        <div className="text-white my-8">
          <p className="text-base text-center flex justify-center items-center gap-2">Wallet Address: 
            {
                copied ? 
                <span>
                <svg className="copyBtn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M22 6.9V11.1C22 14.6 20.6 16 17.1 16H16V12.9C16 9.4 14.6 8 11.1 8H8V6.9C8 3.4 9.4 2 12.9 2H17.1C20.6 2 22 3.4 22 6.9Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
              </span> :
            <span onClick={copyToClipboard}>
                <svg className="copyBtn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M16 12.9V17.1C16 20.6 14.6 22 11.1 22H6.9C3.4 22 2 20.6 2 17.1V12.9C2 9.4 3.4 8 6.9 8H11.1C14.6 8 16 9.4 16 12.9Z" fill="#fff"></path> <path d="M17.0998 2H12.8998C9.81668 2 8.37074 3.09409 8.06951 5.73901C8.00649 6.29235 8.46476 6.75 9.02167 6.75H11.0998C15.2998 6.75 17.2498 8.7 17.2498 12.9V14.9781C17.2498 15.535 17.7074 15.9933 18.2608 15.9303C20.9057 15.629 21.9998 14.1831 21.9998 11.1V6.9C21.9998 3.4 20.5998 2 17.0998 2Z" fill="#fff"></path> </g></svg>
            </span>
            
            }
          </p>
          <code className="text-2xl text-[#16fb9a] break-all text-center">{WALLET_ADDRESS}</code>
        </div>
        {error ? (
          <p className="text-red-500 text-center text-base">{error}</p>
        ) : (
          <div>
            <p className="text-base text-center text-white">Balance:</p>
            <h3 className="text-4xl text-[#9a44ff] text-center">{loading ? "Loading..." : `${balance} SOL`}</h3>
          </div>
        )}
        <div className="flex flex-col gap-4 pt-10">
          <div className="flex justify-between items-end gap-12">
            <p className="text-lg text-slate-100">{barPercent}%</p>
            <p className="text-lg text-slate-100">{MAXSOL.toLocaleString()}</p>
          </div>
          <div className="w-full h-3 rounded-xl bg-slate-700 overflow-hidden">
            <div className="h-full rounded-xl bg-gradient-to-r from-[#16fb9a] to-[#9a44ff]" style={{ width:`${barPercent}%` }}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SolanaFixAddress;
